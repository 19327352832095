body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--slate-800);
  text-align: left;
  background-color: white;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.hidden{
  display: none;
}

.container {
  width: 100%;
  padding: 60px 20px;
  margin-right: auto;
  margin-left: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 767px) {
    max-width: 766px;
  }
}


.fn__container {
  position: relative;
}

.fn__video {
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #AFBDC0;
}

.player-cb {
  height: 70px;
  padding-top: 20px;
  opacity: 1;
  visibility: visible;
  transition: visibility .1s, opacity .1s;
  background-image: linear-gradient(0deg, var(--slate-900), transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  &__button {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
    text-transform: none;
    transition: none;
    color: white;
    cursor: pointer;
  }

  .button-play,
  .button-mute,
  .button-reset {
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
  }

  .button-play {

    order: 2;
    width: 4em;
  }

  .button-reset {
    min-width: 2em;
    padding-left: 1em;
    padding-right: 1em;
    width: auto;
  }

  .button-mute {
    order: 1;
    min-width: 2em;
    padding-left: 1em;
    padding-right: 1em;
    width: auto;
  }
}

.playback-container {
  border-top: 1px solid var(--slate-600);
  padding: 25px 20px 20px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--slate-900);
  margin-bottom: 25px;
}

.pb-times {
  color: white;
  font-size: 15px;
  padding: 15px 15px 0 0;
}

.playback {
  position: relative;
  padding: 0 20px;
  margin-right: 18px;
  height: 10px;

  .seekable,
  .progress,
  .grabber {
    position: absolute;
    cursor: pointer;
  }

  .seekable {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(240, 240, 240);
  }

  .grabber {
    top: -4px;
    bottom: -4px;
    width: 18px;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    transition: transform 0.2s ease;
    z-index: 1;
  }

  .grabber:hover {
    transform: scaleY(1.2);
  }

  .grabber.start {
    background-color: var(--lime-400);
  }

  .grabber.end {
    background-color: var(--orange-400);
  }

  .progress {
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background: var(--blue-600)
  }
}

.controls {
  gap: 10px;

  .button-trim-save,
  .button-next {
    color: #fff;
    cursor: pointer;
    padding: 7px 14px;
    border-radius: 4px;
    min-width: 65px;
  }

  .button-trim-save {
    border: 1px solid var(--blue-600);
    background-color: var(--blue-600);

    &:hover,
    &:active {
      border-color: var(--blue-700);
      background-color: var(--blue-700);
    }

    &:disabled,
    &:disabled:hover,
    &.disabled {
      opacity: 0.50;
      cursor: not-allowed;
      border: 1px solid var(--blue-600);
      background-color: var(--blue-600);
    }
  }

  .button-next {
    border: 1px solid var(--lime-500);
    background-color: var(--lime-500);

    &:hover,
    &:active {
      border-color: var(--lime-600);
      background-color: var(--lime-600);
    }

    &:disabled,
    &:disabled:hover,
    &.disabled {
      opacity: 0.50;
      cursor: not-allowed;
      border: 1px solid var(--lime-500);
      background-color: var(--lime-500);
    }
  }
}


.b-spinner {
  position: absolute;
  width: 100%;
  height: 100%;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
  }

  svg {
    animation: rotate 1.4s linear infinite;
    fill: currentColor;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
}


.inline-sping {
  display: inline-block;

  svg {
    animation: rotate 1.4s linear infinite;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
}
